import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useI18n from 'hooks/I18n/useI18n';

import { ORGANIC_FILTER } from 'modules/utils/saleCategoriesAndFilters';
import AppChannel from 'modules/channels/App';
import {
    filterByPromotion,
    getCurrentFarmsOrderedByPriority,
} from 'modules/utils/saleCategoriesAndFilters';
import { getUniqTagKeyList } from 'models/tag';
import { getDistributionStart } from 'models/distributions';

import CurrentCategoryInformation from 'components/Sale/ProductIdentity/CurrentCategoryInformation.jsx';
import { getProductsListWidth } from 'components/Sale/designUtils';
import EmptyDistributionProducts from 'components/Sale/EmptyDistributionProducts.jsx';
import ProductsList from 'components/Sale/ProductIdentity/ProductsList/ProductsList.jsx';

const getSectionName = (currentCategory, product) => {
    if (currentCategory.isTrueCategory) {
        return product.category_name_en;
    }

    return currentCategory.englishName;
};

export const ProductsPanel = props => {
    const {
        addOfferToBasket,
        assembly,
        categoryHasOrganicProducts,
        currentCategory,
        currentFarmId,
        currentFarms,
        currentFilter,
        currentSubCategoryId,
        currentSubCategories,
        distribution,
        isLoading,
        isSmallWidth,
        isFilterMobilePanelOpen,
        products,
        productTypes,
        setCurrentFarm,
        toggleFilterMobilePanel,
        onClickOnProduct,
        joinAssembly,
        isAnonymous,
        isMemberOfAssembly,
        joinAssemblyStatus,
        hasNoProducts,
        currentFilterTag,
    } = props;
    const { trans } = useI18n();
    const productsListRef = useRef();
    const prevCategoryRef = useRef();

    const scrollToFarm = useCallback(
        farmId => {
            setCurrentFarm(farmId);
            return productsListRef.current.scrollToFarm(farmId);
        },
        [setCurrentFarm]
    );

    const tagList = useMemo(() => getUniqTagKeyList(products), [products]);

    const productsFiltered = useMemo(() => filterByPromotion(products, currentFilterTag), [
        products,
        currentFilterTag,
    ]);

    const farmsFiltered = useMemo(
        () => getCurrentFarmsOrderedByPriority(currentFarms, productsFiltered),
        [productsFiltered, currentFarms]
    );

    useEffect(() => {
        if (currentCategory.current?.categoryKey !== currentCategory.categoryKey) {
            products.length > 0 &&
                AppChannel.vent.trigger(
                    'analytics:itemList:view',
                    `category_${currentCategory.id}`,
                    getSectionName(currentCategory, products[0]),
                    assembly,
                    products
                );
            prevCategoryRef.current = currentCategory;
        }
    }, [currentCategory, assembly, products]);
    if (hasNoProducts) {
        return (
            <div className="emptyDistributionProducts">
                <EmptyDistributionProducts
                    assemblyId={assembly.id}
                    isNotAMember={!isMemberOfAssembly}
                    isAnonymous={isAnonymous}
                    joinAssembly={joinAssembly}
                    distributionStartDate={getDistributionStart(distribution)}
                    joinAssemblyStatus={joinAssemblyStatus}
                />
            </div>
        );
    }
    return (
        <div className="pi-sale-content">
            <CurrentCategoryInformation
                tagList={tagList}
                categoryHasOrganicProducts={categoryHasOrganicProducts}
                currentCategory={currentCategory}
                currentFarmId={currentFarmId}
                currentFarms={farmsFiltered}
                currentFilter={currentFilter}
                currentSubCategoryId={currentSubCategoryId}
                currentSubCategories={currentSubCategories}
                isFilterMobilePanelOpen={isFilterMobilePanelOpen}
                productsCount={productsFiltered.length}
                onClickFunction={scrollToFarm}
                toggleFilterMobilePanel={toggleFilterMobilePanel}
                isSmallWidth={isSmallWidth}
                currentTag={currentFilterTag}
            />
            <div
                className={classnames('pi-sale-content-products-list', {
                    'is-loading': isLoading,
                })}
            >
                {products.length === 0 && currentFilter === ORGANIC_FILTER && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: trans('products.filters.onlyBioWarning'),
                        }}
                        style={{ width: getProductsListWidth() }}
                    />
                )}
                <ProductsList
                    addOfferToBasket={addOfferToBasket}
                    assembly={assembly}
                    distribution={distribution}
                    farms={farmsFiltered}
                    products={productsFiltered}
                    productTypes={productTypes}
                    ref={productsListRef}
                    setCurrentFarm={setCurrentFarm}
                    onClickOnProduct={onClickOnProduct}
                />
            </div>
        </div>
    );
};
ProductsPanel.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    assembly: PropTypes.object.isRequired,
    categoryHasOrganicProducts: PropTypes.bool,
    currentCategory: PropTypes.object.isRequired,
    currentFarmId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentFarms: PropTypes.array.isRequired,
    currentFilter: PropTypes.string.isRequired,
    currentSubCategories: PropTypes.array.isRequired,
    currentSubCategoryId: PropTypes.number,
    distribution: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isSmallWidth: PropTypes.bool,
    isFilterMobilePanelOpen: PropTypes.bool,
    products: PropTypes.array.isRequired,
    productTypes: PropTypes.object.isRequired,
    setCurrentFarm: PropTypes.func,
    toggleFilterMobilePanel: PropTypes.func,
    onClickOnProduct: PropTypes.func.isRequired,
    joinAssembly: PropTypes.func.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    isMemberOfAssembly: PropTypes.bool.isRequired,
    joinAssemblyStatus: PropTypes.string.isRequired,
    hasNoProducts: PropTypes.bool.isRequired,
    currentFilterTag: PropTypes.string,
};
export default ProductsPanel;
