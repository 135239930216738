import reducerRegistry from '../../reducers/ReducerRegistry';
import { getInvitations, declineInvitation, acceptInvitation } from 'models/demand.js';
import * as R from 'ramda';
// actions
export const fetchInvitations = farmId => (dispatch, getState) => {
    if (getState().farmerInvitations.invitationsStatus === 'INIT') {
        dispatch({
            type: 'FETCH_INVITATIONS_REQUEST',
        });
        return getInvitations(farmId)
            .then(invitations => {
                const pendingInvitations = R.filter(
                    R.propEq('status', 'pending_validation'),
                    invitations
                );
                dispatch({
                    type: 'FETCH_INVITATIONS_SUCCESS',
                    payload: {
                        invitations: pendingInvitations,
                        pendingInvitationsCount: pendingInvitations.length,
                    },
                });
            })
            .catch(err => {
                throw err;
            });
    }
    return Promise.resolve();
};

export const acceptAssemblyInvitation = (invitationId, deliveryMeanOption) => (
    dispatch,
    getState
) => {
    if (getState().farmerInvitations.invitationIsSaving === null) {
        dispatch({
            type: 'INVITATION_REQUEST',
            payload: { invitationIsSaving: `accept_${invitationId}` },
        });
        return acceptInvitation(invitationId, deliveryMeanOption)
            .then(() => {
                dispatch({
                    type: 'ACCEPT_INVITATION_SUCCESS',
                    payload: R.evolve(
                        {
                            invitations: R.reject(R.propEq('id', invitationId)),
                            pendingInvitationsCount: R.dec(),
                        },
                        getState().farmerInvitations
                    ),
                });
            })
            .catch(err => {
                throw err;
            });
    }
    return Promise.resolve();
};

export const declineAssemblyInvitation = invitationId => (dispatch, getState) => {
    if (getState().farmerInvitations.invitationIsSaving === null) {
        dispatch({
            type: 'INVITATION_REQUEST',
            payload: { invitationIsSaving: `decline_${invitationId}` },
        });
        return declineInvitation(invitationId)
            .then(() => {
                dispatch({
                    type: 'DECLINE_INVITATION_SUCCESS',
                    payload: R.evolve(
                        {
                            invitations: R.reject(R.propEq('id', invitationId)),
                            pendingInvitationsCount: R.dec(),
                        },
                        getState().farmerInvitations
                    ),
                });
            })
            .catch(err => {
                throw err;
            });
    }
    return Promise.resolve();
};

// reducers
const farmerInvitationsReducer = (
    state = {
        invitations: [],
        invitationsStatus: 'INIT',
        invitationIsSaving: null,
        pendingInvitationsCount: null,
    },
    action
) => {
    switch (action.type) {
        case 'FETCH_INVITATIONS_REQUEST': {
            return {
                ...state,
                invitationsStatus: 'FETCHING',
            };
        }
        case 'FETCH_INVITATIONS_SUCCESS': {
            return {
                ...state,
                invitations: action.payload.invitations,
                pendingInvitationsCount: action.payload.pendingInvitationsCount,
                invitationsStatus: 'FETCHED',
            };
        }
        case 'INVITATION_REQUEST': {
            return {
                ...state,
                invitationIsSaving: action.payload.invitationIsSaving,
            };
        }
        case 'ACCEPT_INVITATION_SUCCESS': {
            return {
                ...state,
                invitations: action.payload.invitations,
                invitationIsSaving: null,
                pendingInvitationsCount: action.payload.pendingInvitationsCount,
            };
        }
        case 'DECLINE_INVITATION_SUCCESS': {
            return {
                ...state,
                invitations: action.payload.invitations,
                invitationIsSaving: null,
                pendingInvitationsCount: action.payload.pendingInvitationsCount,
            };
        }
        default:
            return state;
    }
};

// store
reducerRegistry.register({
    farmerInvitations: farmerInvitationsReducer,
});
