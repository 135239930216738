import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { INITIAL_LOADING, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import {
    Box,
    Grid,
    Heading,
    LoadingAnimation,
    Paragraph,
    Separator,
    Text,
    Link,
} from 'components/ui';
import Utils from 'modules/utils';
const { filerPhoto, linkTo } = Utils;
import { sortByPhotoAndId } from 'models/products';
import './TemporaryI18n.js';

export class HighlightCluster extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        clusters: PropTypes.array.isRequired,
        status: statusPropType,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { assembly, clusters, status, trans } = this.props;

        if (status === INITIAL_LOADING) {
            return <LoadingAnimation type="spinner" />;
        }

        if (clusters.length === 0) {
            return null;
        }

        return (
            <div>
                {clusters.map(cluster => {
                    const description = R.propOr(
                        trans('highlighted_producers.info'),
                        'description',
                        cluster
                    );
                    return (
                        <Box className="u-mb-6" key={cluster.id}>
                            <Heading className="u-mb-2" size={3}>
                                {cluster.title}
                            </Heading>
                            <Paragraph className="u-mb-4">{description}</Paragraph>
                            {cluster.farms.map((farm, index) => {
                                const productsToDisplay = R.take(
                                    3,
                                    sortByPhotoAndId(farm.products)
                                );

                                const productsString = R.concat(
                                    R.concat(
                                        `${trans('distributions.prepare.products')} `,
                                        R.uniq(
                                            productsToDisplay.map(product => product.categoryName)
                                        ).join(', ')
                                    ),
                                    ', etc...'
                                );

                                return (
                                    <div key={farm.id}>
                                        {index !== 0 && <Separator spacing="medium" />}
                                        <Grid sm="1|7|3|1" xs="2|10|6|6">
                                            <img
                                                src={filerPhoto(
                                                    farm.farmPhotoUuid,
                                                    'small',
                                                    'user'
                                                )}
                                                className="highlightClusterFarmImage"
                                                width="100%"
                                            />
                                            <div>
                                                <Text withTag>
                                                    {trans(
                                                        'distributions.prepare.suggestedProducer'
                                                    )}
                                                </Text>
                                                <Paragraph className="u-mb-2">
                                                    <strong>{farm.farmName}.</strong>{' '}
                                                    {productsString}
                                                </Paragraph>
                                            </div>
                                            <div>
                                                {productsToDisplay.map(product => {
                                                    return (
                                                        <img
                                                            key={product.id}
                                                            src={filerPhoto(
                                                                product.photoId,
                                                                'small',
                                                                'user'
                                                            )}
                                                            className="highlightClusterProductImage"
                                                            title={product.name}
                                                            alt={product.name}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <Link
                                                href={linkTo(
                                                    `assemblies/${assembly.id}/producers/${farm.farmId}`
                                                )}
                                                variant="primary"
                                                noDecoration
                                                className="u-right"
                                            >
                                                <Text>{trans('farms.seeDetails')} ></Text>
                                            </Link>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </Box>
                    );
                })}
            </div>
        );
    }
}

export default connectToI18n(HighlightCluster);
