import React from 'react';
import ValidateSCU from './ValidateSCU.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userSelector, imperativeEditUser, refreshCurrentUser } from 'modules/currentUser';
import { updateUser } from 'models/users.js';
import * as R from 'ramda';
import AppChannel from 'modules/channels/App';
import { createLeaderProfile, createFarm } from 'models/users';

export class ValidateSCUContainer extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        imperativeEditUser: PropTypes.func.isRequired,
        refreshCurrentUser: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
    };

    state = { isAccepted: false, phoneNumber: this.props.user.phone };

    onAccept = (_, checked) => {
        this.setState({ isAccepted: checked });
    };

    onPhoneChange = (_, value) => {
        this.setState({ phoneNumber: value });
    };

    onSubscribe = () => {
        const { user, match } = this.props;
        updateUser(user.id, {
            hasAcceptedSCU: this.state.isAccepted,
            phone: this.state.phoneNumber,
        }).then(() => {
            this.props.imperativeEditUser(
                R.evolve({
                    phone: R.always(this.state.phoneNumber),
                    hasAcceptedSCU: R.always(this.state.isAccepted),
                })
            );
            if (match.params.type === 'host') {
                this.handleCreateLeaderProfile();
            } else {
                this.handleOpenFarmSpace();
            }
        });
    };

    handleOpenFarmSpace = () => {
        createFarm()
            .then(() => {
                this.props.refreshCurrentUser().then(() => {
                    AppChannel.commands.execute('navigate', 'producers/folder', true);
                });
            })
            .catch(() => {});
    };

    handleCreateLeaderProfile = () => {
        this.setState({
            creatingLeaderProfile: true,
        });

        createLeaderProfile(this.props.user.id)
            .then(() => {
                this.props.imperativeEditUser(
                    R.mergeRight({
                        leaderProfile: {
                            blob: '{}',
                            status: 'init',
                        },
                    })
                );
                AppChannel.commands.execute('navigate', 'progress', true);
            })
            .catch(() => {});
    };

    render() {
        return (
            <ValidateSCU
                type={this.props.match.params.type || ''}
                isAccepted={this.state.isAccepted}
                onAccept={this.onAccept}
                onPhoneChange={this.onPhoneChange}
                onSubscribe={this.onSubscribe}
                phoneNumber={this.state.phoneNumber}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: userSelector(state),
});

export default connect(mapStateToProps, { imperativeEditUser, refreshCurrentUser })(
    ValidateSCUContainer
);
