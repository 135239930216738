import { get } from 'modules/api';
import * as R from 'ramda';
import environment from 'modules/utils/environment';

export const fetchCountries = () => get('countries/');

export const fetchCities = ({ zipCode, country }) =>
    get('cities/', {
        zipCode,
        country,
    });

export const openCountriesList = ['BE', 'FR', 'DE', 'ES', 'IT', 'PF', 'CH', 'NL'];
export const countriesAllowedToContactMembersByEmail = ['FR', 'BE', 'ES', 'PF'];
export const countriesWithVatFlatRateScheme = ['IT'];
export const countriesWithNoInterregionalSpecialFee = ['IT', 'ES', 'BE', 'NL'];

if (environment !== 'production') {
    // In progress countries
}

let _localCountriesCacheJSON = [];

export const sortByName = R.sortBy(R.prop('name'));

const sortByNameOpenFirst = countries => {
    const separator = {
        id: 0,
        code: '-',
        name: '-',
    };

    return R.flatten(
        R.intersperse(
            separator,
            R.partition(
                country => R.contains(country.code, openCountriesList),
                sortByName(countries)
            )
        )
    );
};

export const getCountriesAsJSON = () => {
    if (_localCountriesCacheJSON.length > 0) {
        return Promise.resolve(_localCountriesCacheJSON);
    }

    return get('countries/').then(({ countries }) => {
        _localCountriesCacheJSON = sortByNameOpenFirst(countries);

        return _localCountriesCacheJSON;
    });
};
