import * as R from 'ramda';
import emptyObject from 'modules/utils/emptyObject';
import { getYear } from 'modules/utils/dateAndTime.js';
import { dateStringToDateObject } from 'models/date.js';

const MIN_YEAR_BIRTHDAY = 1900;

const _wrap = validateFn => validators => subject => {
    const temp = validateFn(validators)(subject);
    return [R.isEmpty(temp), temp];
};

const _all = validators => subject =>
    R.transduce(
        // Theoretically passing null to mergeWith should not break
        // R.mergeWith(R.concat, {}, null) and
        // R.mergeWith(R.concat, null, {}) are ok ...
        // but R.mergeWith(R.concat, {a: 'a'}, null) is broken
        // ref: https://github.com/ramda/ramda/issues/2586
        R.map(validator => validator(subject) || emptyObject),
        R.mergeWith(R.concat),
        emptyObject
    )(validators);

const all = _wrap(_all);

const _eager = validators => subject => {
    if (validators.length === 0) {
        return emptyObject;
    }
    const validator = R.head(validators);
    if (R.isNil(validator(subject))) {
        return _eager(R.tail(validators))(subject);
    }
    return validator(subject);
};
const eager = _wrap(_eager);

const prop = (property, validator) => subject => {
    const error = R.pipe(R.prop(property), validator)(subject);
    if (error) {
        return R.pipe(R.of, R.objOf(property))(error);
    }
    return null;
};

// Read as "error/invalid when ..."
const when = R.curry((predicate, error, subject) => (predicate(subject) ? error : null));

// read as "error/invalid unless ..."
const unless = R.curry((predicate, error, subject) => (!predicate(subject) ? error : null));

export const validate = {
    all,
    eager,
    _eager,
    prop,
    when,
    unless,
};

const isBlank = R.either(R.isEmpty, R.isNil);
export const assertNotBlank = when(isBlank);

const isTrue = R.equals(true);
export const assertChecked = unless(isTrue);

export const assertStartWith = str =>
    when(R.both(R.complement(isBlank), R.complement(R.startsWith(str))));

const isNotEmail = R.complement(R.test(/^[^@]+@[^@]+\.[^@]+$/));
export const assertValidEmail = when(R.either(isBlank, isNotEmail));

const isShorter = min => R.compose(R.gt(min), R.length);

export const assertLongerThan = min => when(R.either(isBlank, isShorter(min)));
export const assertLongerThanOrBlank = min =>
    when(R.allPass([R.complement(isBlank), isShorter(min)]));

const isEqualTo = to => R.equals(to);
export const assertIsEqualTo = to => unless(isEqualTo(to));

const isNotValidPassword = R.complement(R.test(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/));
export const assertValidPassword = when(R.either(isBlank, isNotValidPassword));

const isDateEmpty = R.either(
    R.equals('--'),
    R.pipe(R.split('-'), R.map(R.isEmpty), R.any(R.equals(true)))
);
export const assertDateNotBlank = when(R.either(isBlank, isDateEmpty));

const isNotValidDate = date => {
    const dateObject = dateStringToDateObject(date);
    return (
        dateObject.day < 1 || dateObject.day > 31 || dateObject.month < 1 || dateObject.month > 12
    );
};

export const assertValidDate = when(R.either(isDateEmpty, isNotValidDate));

const isNotBirthdateValid = date => R.gt(MIN_YEAR_BIRTHDAY, getYear(date));
export const assertValidBirthdate = when(R.either(isNotValidDate, isNotBirthdateValid));

const isNotInMinRange = min => R.lte(min);
const isNotInMaxRange = max => R.gte(max);
export const assertValidRange = (min, max) =>
    when(R.either(R.complement(isNotInMinRange(min)), R.complement(isNotInMaxRange(max))));
