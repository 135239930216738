import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { READY, PROCESSING, FAILED } from 'modules/utils/ajaxStatuses';
import { sendProposal } from 'models/demand.js';
import { formatDistance } from 'modules/utils/distances';
import AssemblyName from 'components/AssemblyName.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import RadioButton from 'components/RadioButton.jsx';
import { Heading, Grid, Text, Button, Icon } from 'components/ui';
import Utils from 'modules/utils';
import * as R from 'ramda';

const { getLocalizedDistance } = Utils;

export class ProposalPanel extends Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        farmId: PropTypes.number.isRequired,
        onClose: PropTypes.func.isRequired,
        setProposalAsPending: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        farmCoordinates: PropTypes.object,
    };

    state = {
        status: READY,
        deliveryMeanOption: 'dropping',
    };

    sendProposal = (assemblyId, message) => {
        this.setState({ status: PROCESSING });

        return sendProposal({
            assemblyId,
            farmId: this.props.farmId,
            message,
            deliveryMean: this.state.deliveryMeanOption,
        })
            .then(() => {
                this.setState({
                    status: READY,
                });
                this.props.setProposalAsPending(assemblyId);
            })
            .catch(() => {
                this.setState({ status: FAILED });
            });
    };

    editDeliveryMeanOption = value => {
        this.setState({ deliveryMeanOption: value });
    };

    render() {
        const { assembly, onClose, trans, i18n, farmCoordinates } = this.props;

        const clickOnAccept = () => {
            this.sendProposal(assembly.id, this.textarea.value);
        };

        const assemblyCity =
            assembly.city || R.path(['place', 'address', 'city', 'name'], assembly);
        const distanceToFarm = assembly.distanceInMeters
            ? formatDistance(assembly.distanceInMeters, 'm', i18n)
            : getLocalizedDistance(
                  {
                      latitude: farmCoordinates.latitude,
                      longitude: farmCoordinates.longitude,
                  },
                  assembly.place.address.coordinates,
                  assembly.place.address.country.code
              );

        return (
            <StructuredSidePanel
                isOpened
                onClose={onClose}
                actions={
                    <Grid xs="6|6">
                        <Button block weight="link" onClick={onClose}>
                            {trans('global.cancel')}
                        </Button>
                        <Button
                            block
                            variant="success"
                            onClick={() => {
                                clickOnAccept(assembly.id);
                            }}
                            disabled={this.state.status === PROCESSING}
                            loading={this.state.status === PROCESSING}
                        >
                            {trans('farm.sendProposal')}
                        </Button>
                    </Grid>
                }
            >
                <div className="u-mb-6">
                    <Heading className="u-flexible-item u-mb-1" size={2} responsive={false}>
                        {trans('hives.horizontalBox.suggestMyProducts')}
                    </Heading>
                    <AssemblyName
                        name={assembly.name}
                        city={`${assemblyCity} - ${distanceToFarm}`}
                        inline
                    />
                </div>
                <div className="u-mb-4">
                    <Heading size={4} className="u-mb-2">
                        {trans('deliveryMean')}
                    </Heading>
                    <Text>{trans('deliveryMean.selectLabel')}</Text>
                </div>
                <div className="u-mb-4">
                    <RadioButton
                        className="u-mb-1"
                        inputId="dropping"
                        isSelected={this.state.deliveryMeanOption === 'dropping'}
                        name="deliveryMeanOption"
                        onChange={this.editDeliveryMeanOption}
                        value="dropping"
                        variant="circle"
                    >
                        <Text
                            inline
                            weight={
                                this.state.deliveryMeanOption === 'dropping' ? 'bold' : 'normal'
                            }
                        >
                            {trans('deliveryMean.optionDropping')}
                        </Text>
                    </RadioButton>
                    <Text className="u-mb-4" color="info">
                        {trans('deliveryMean.optionDropping.info')}
                    </Text>
                    <RadioButton
                        inputId="expedition"
                        isSelected={this.state.deliveryMeanOption === 'expedition'}
                        name="deliveryMeanOption"
                        onChange={this.editDeliveryMeanOption}
                        value="expedition"
                        variant="circle"
                    >
                        <Text
                            inline
                            weight={
                                this.state.deliveryMeanOption === 'expedition' ? 'bold' : 'normal'
                            }
                        >
                            {trans('deliveryMean.optionExpedition')}
                            <Icon
                                src={require('icons/shipping.svg')}
                                className="u-pl-1"
                                size="x-large"
                            />
                        </Text>
                    </RadioButton>
                    <Text className="u-mb-2" color="info">
                        {trans('deliveryMean.optionExpedition.info')}
                    </Text>
                </div>
                <Heading size={4} className="u-mb-2">
                    {trans('deliveryMean.proposalMessageLabel')}
                </Heading>
                <textarea
                    className="fa-input u-w-100 u-mb-4"
                    name="message"
                    ref={c => {
                        this.textarea = c;
                    }}
                    cols="30"
                    rows="7"
                    placeholder={trans('hives.box.placeHolder.messageForPropositionToLeader')}
                    defaultValue={trans('hives.box.messageForPropositionToLeader')}
                />
                {this.state.status === FAILED && (
                    <Text color="warning" className="u-mb-2">
                        {trans('global.error.retryMessage')}
                    </Text>
                )}
            </StructuredSidePanel>
        );
    }
}

export default connectToI18n(ProposalPanel);
