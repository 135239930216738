import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { imperativeEditUser, userSelector } from 'modules/currentUser';
import { isMemberOfAssembly } from 'models/users';
import { userJoinAssembly } from 'api/hives';
import useI18n from 'hooks/I18n/useI18n';
import useAnalytics from 'hooks/Analytics/useAnalytics';
import Button from 'src/components/atoms/Button/Button.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import { redirectTo } from 'modules/utils';

const NoOpenSale = ({ assemblyId }) => {
    const { trans, i18n } = useI18n();
    const { analytics } = useAnalytics();
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const handleJoinHiveBtnClick = () => {
        userJoinAssembly(assemblyId, user.id)
            .then(assemblyResponse => {
                dispatch(
                    imperativeEditUser(
                        R.evolve({
                            hivesAsMember: R.append(assemblyResponse),
                        })
                    )
                );

                analytics.trackAssemblyJoin(assemblyResponse.id, assemblyResponse.name);
            })
            .catch(() => redirectTo(`/${i18n}/join`));
    };

    const userIsAlreadyMemberOfHive = isMemberOfAssembly(assemblyId, user);

    return (
        <div className="pi-sales-block-empty-state">
            <div className="img-container">
                <img src="/assets/images/assemblyHome/no-open-sale.png" />
            </div>
            <div className="text-container">
                <Text size="20px" lineHeight="28px" weight="600">
                    {trans('assembly.noOpenSale')}
                </Text>
                <div className="text-body">
                    <Text size="16px" lineHeight="24px">
                        {userIsAlreadyMemberOfHive
                            ? trans('distributions.noOpenSale.text')
                            : trans('hiveHome.distribution.noOpenSale.notAMember')}
                    </Text>
                    {userIsAlreadyMemberOfHive && (
                        <Text size="16px" lineHeight="24px">
                            {trans('hives.member.alert.upcomingSaleNoDate')}
                        </Text>
                    )}
                </div>
                {!userIsAlreadyMemberOfHive && (
                    <Button
                        className="pi-sales-block-empty-state-cta"
                        onClick={handleJoinHiveBtnClick}
                    >
                        {trans('hives.pageHeaderAsVisitor.joinHive')}
                    </Button>
                )}
            </div>
        </div>
    );
};

NoOpenSale.propTypes = {
    assemblyId: PropTypes.number.isRequired,
};

export default NoOpenSale;
