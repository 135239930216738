import PropTypes from 'prop-types';
import React from 'react';
import AssembliesSection from './AssembliesSection.jsx';
import FarmSection from './FarmSection.jsx';
import { userSelector, imperativeEditUser } from 'modules/currentUser';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { createLeaderProfile, isEligibleForPro } from 'models/users';
import AppChannel from 'modules/channels/App';
import { useDispatch } from 'react-redux';

export const ContextPanel = ({ userAssemblies, hostAssemblies, farm, hasPendingLeaderProfile }) => {
    const currentUser = useSelector(userSelector);
    const dispatch = useDispatch();

    const isUserFrench = currentUser?.locale === 'fr_FR';

    const handleCreateLeaderProfile = () => {
        const allowCreation = isEligibleForPro(currentUser);

        if (allowCreation) {
            createLeaderProfile(currentUser.id)
                .then(() => {
                    dispatch(
                        imperativeEditUser(
                            R.mergeRight({
                                leaderProfile: {
                                    blob: '{}',
                                    status: 'init',
                                },
                            })
                        )
                    );
                    AppChannel.commands.execute('navigate', 'progress', true);
                })
                .catch(() => {});
        } else {
            AppChannel.commands.execute('navigate', 'validate/scu/host', true);
        }
    };

    return (
        <div className="contextPanel u-p-0">
            <AssembliesSection
                userAssemblies={userAssemblies}
                hostAssemblies={hostAssemblies}
                hasPendingLeaderProfile={hasPendingLeaderProfile}
                canCreateLeaderProfile={!currentUser.leaderProfile}
                createLeaderProfile={handleCreateLeaderProfile}
                isUserFrench={isUserFrench}
                hasFarm={!!farm}
                shouldShowMiniRucheLink
            />
            <FarmSection farm={farm} />
        </div>
    );
};

ContextPanel.propTypes = {
    userAssemblies: PropTypes.array,
    hostAssemblies: PropTypes.array,
    farm: PropTypes.object,
    hasPendingLeaderProfile: PropTypes.bool,
};

export default ContextPanel;
