import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class Separator extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        spacing: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
        color: PropTypes.oneOf(['gray1']),
    };

    static defaultProps = {
        spacing: 'large',
    };

    render() {
        const { className, spacing, color } = this.props;

        return (
            <hr
                className={classnames('separator', className, {
                    [`separator--${spacing}Margins`]: spacing,
                    [`separator--${color}`]: color,
                })}
            />
        );
    }
}
