import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import geodist from 'geodist';

import { getAssemblyByIdSelector, userSelector } from 'modules/currentUser';
import { switchAssembly } from 'modules/navigation';

import { fetchAssemblyPickups } from 'api/pickups';
import { fetchHives, fetchHive, fetchFarmsGallery } from 'api/hives';
import {
    addMessageToDiscussion,
    createDiscussionInAssembly,
    deleteDiscussion,
    deleteMessageFromDiscussion,
    getAssemblyDiscussionsWithCounters,
} from 'api/discussions';
import { getCurrentDistributions, getDistributionDetails } from 'models/distributions';
import { isMemberOfAssembly } from 'models/users';
import useAnalytics from 'hooks/Analytics/useAnalytics';
import useI18n from 'hooks/I18n/useI18n';
import { LoadingAnimation } from 'components/ui';
import Heading from 'components/Heading.jsx';
import useResponsive from 'hooks/Navigation/useResponsive';
import { groupProductsByAlreadyOrderedFarms } from 'models/products';
import { filterProducts, HIGHLIGHTED_CATEGORY } from 'modules/utils/saleCategoriesAndFilters';
import PlaceMapModal from 'components/Maps/ProductIdentity/PlaceMapModal.jsx';
import TermsFooter from 'components/ProductIdentity/TermsFooter.jsx';
import Header from './Header.jsx';
import SideInfos from './SideInfos.jsx';
import NewsSection from './NewsSection.jsx';
import NoOpenSale from './NoOpenSale.jsx';
import FarmsSection from './FarmsSection.jsx';
import HostSection from './HostSection.jsx';
import HiveUnderConstruction from './HiveUnderConstruction.jsx';
import Distribution from './Distribution.jsx';
import assemblyHomeReducer, {
    getInitialState,
    ALL_DATA_LOADED,
    ASSEMBLY_DATA_CHANGED,
    ASSEMBLY_DATA_LOADED,
    CLOSE_PLACE_MODAL_BTN_CLICKED,
    NEW_COMMENT_HAS_BEEN_POSTED,
    NEW_DISCUSSION_HAS_BEEN_POSTED,
    NEW_DISCUSSIONS_HAVE_BEEN_LOADED,
    OPEN_PLACE_MODAL_BTN_CLICKED,
    COMMENT_HAS_BEEN_DELETED,
    DISCUSSION_HAS_BEEN_DELETED,
} from './assemblyHomeReducer';
import ProductCard, { MODE_LARGE } from 'components/Sale/ProductIdentity/ProductCard.jsx';
import { getProducts } from 'models/distributions.js';

const FARMS_GALLERY_SIZE = 16;

async function getDistributionsData(currentDistributions) {
    const distributionIds = R.pluck('distributionId', currentDistributions);

    if (R.isEmpty(distributionIds)) {
        return [];
    }

    const distributions = [];
    for (const distributionId of distributionIds) {
        // TODO: we shouldn't have to fetch the distribution details one by one, instead prefer creating a new endpoint
        await getDistributionDetails(distributionId).then(fetchedDistribution => {
            distributions.push(fetchedDistribution);
        });
    }

    return distributions;
}

const AssemblyHomeContainer = ({ assemblyId }) => {
    const { analytics } = useAnalytics();
    const { trans, i18n } = useI18n();
    const preloadedAssembly = useSelector(getAssemblyByIdSelector(assemblyId));
    const user = useSelector(userSelector);
    const globalDispatch = useDispatch();
    const [state, dispatch] = useReducer(
        assemblyHomeReducer,
        getInitialState(preloadedAssembly || {})
    );
    const [products, setProducts] = useState([]);
    const {
        assembly,
        farmsGallery,
        pickups,
        distributions,
        discussions,
        nextDiscussionPage,
        canLoadMoreDiscussions,
        dataLoaded,
        isPlaceModalOpen,
        placeModalEntityType,
        placeModalEntity,
        nearbyAssemblies,
    } = state;
    const distribution = distributions.length ? distributions[0] : {};

    const assemblyRef = useRef(assembly);
    const userIsMemberOfAssembly = isMemberOfAssembly(assembly.id, user);
    const isSmallWidth = useResponsive();

    useEffect(() => {
        assemblyRef.current = assembly;

        if (R.isEmpty(assembly)) {
            return;
        }

        document.title = trans('seo.hivePage.title', {
            '%city%': assembly.place.address.city.name,
            '%assembly%': assembly.name,
        });
    }, [assembly, trans]);

    useEffect(() => {
        if (preloadedAssembly) {
            dispatch({
                type: ASSEMBLY_DATA_CHANGED,
                payload: preloadedAssembly,
            });

            return;
        }

        fetchHive(assemblyId).then(fetchedAssembly => {
            dispatch({
                type: ASSEMBLY_DATA_LOADED,
                payload: fetchedAssembly,
            });
        });
    }, [preloadedAssembly, assemblyId]);

    useEffect(() => {
        if (!assembly.id && !assembly.uuid) {
            return;
        }

        globalDispatch(switchAssembly(assembly));

        Promise.all([
            fetchFarmsGallery(assembly.id, FARMS_GALLERY_SIZE),
            fetchAssemblyPickups(assembly.uuid),
            getDistributionsData(getCurrentDistributions(assemblyRef.current)),
            userIsMemberOfAssembly ? getAssemblyDiscussionsWithCounters(assembly.id) : [],
            fetchHives(),
        ]).then(
            ([
                fetchedFarmsGallery,
                fetchedPickups,
                fetchedDistributions,
                fetchedDiscussions,
                assemblies,
            ]) => {
                const distanceToAssembly = R.memoizeWith(R.prop('id'), hive =>
                    geodist(assembly.place.address.coordinates, hive.place.address.coordinates, {
                        unit: 'meters',
                    })
                );
                const localNearbyAssemblies = R.pipe(
                    R.sortBy(distanceToAssembly),
                    R.reject(R.propEq('id', assembly.id)),
                    R.take(6)
                )(assemblies);
                dispatch({
                    type: ALL_DATA_LOADED,
                    payload: {
                        pickups: fetchedPickups,
                        distributions: fetchedDistributions,
                        farmsGallery: fetchedFarmsGallery.photos,
                        discussions: fetchedDiscussions,
                        nearbyAssemblies: localNearbyAssemblies,
                    },
                });

                analytics.trackAssemblyVisit(assemblyRef.current);
            }
        );
    }, [analytics, user, userIsMemberOfAssembly, assembly, globalDispatch]);

    const handleCloseModal = useCallback(() => {
        dispatch({ type: CLOSE_PLACE_MODAL_BTN_CLICKED });
    }, []);

    const handleShowPlaceInMapBtnClick = useCallback((entity, entityType) => {
        dispatch({
            type: OPEN_PLACE_MODAL_BTN_CLICKED,
            payload: {
                entityType,
                entity,
            },
        });
    }, []);

    const handleNewCommentFormSubmit = useCallback(
        (discussionId, content, callback) => {
            addMessageToDiscussion(discussionId, content).then(message => {
                const discussionIndex = R.findIndex(R.propEq('id', discussionId))(discussions);
                const appendMessage = R.evolve({ messages: R.append(message) });

                dispatch({
                    type: NEW_COMMENT_HAS_BEEN_POSTED,
                    payload: R.adjust(discussionIndex, appendMessage)(discussions),
                });

                callback();
            });
        },
        [discussions]
    );

    const handleNewDiscussionFormSubmit = useCallback(
        (title, content, callback) => {
            createDiscussionInAssembly(assemblyId, content, title).then(message => {
                dispatch({
                    type: NEW_DISCUSSION_HAS_BEEN_POSTED,
                    payload: message,
                });

                callback();
            });
        },
        [assemblyId]
    );

    const handleCommentDeletion = useCallback(
        (discussionId, messageUuid) => {
            deleteMessageFromDiscussion(assemblyId, discussionId, messageUuid).then(() => {
                const discussionIndex = R.findIndex(R.propEq('id', discussionId))(discussions);
                const messageIndex = R.findIndex(R.propEq('uuid', messageUuid))(
                    discussions[discussionIndex].messages
                );
                const propertyPath = R.lensPath([discussionIndex, 'messages']);
                const newCommentsList = R.remove(
                    messageIndex,
                    1,
                    discussions[discussionIndex].messages
                );

                dispatch({
                    type: COMMENT_HAS_BEEN_DELETED,
                    payload: R.set(propertyPath, newCommentsList, discussions),
                });
            });
        },
        [discussions, assemblyId]
    );

    const handleDiscussionDeletion = useCallback(
        discussionId => {
            deleteDiscussion(assemblyId, discussionId).then(() => {
                const discussionIndex = R.findIndex(R.propEq('id', discussionId))(discussions);

                dispatch({
                    type: DISCUSSION_HAS_BEEN_DELETED,
                    payload: R.remove(discussionIndex, 1, discussions),
                });
            });
        },
        [discussions, assemblyId]
    );

    const handleLoadMoreMessageClick = useCallback(() => {
        getAssemblyDiscussionsWithCounters(assemblyId, nextDiscussionPage).then(newDiscussions => {
            dispatch({
                type: NEW_DISCUSSIONS_HAVE_BEEN_LOADED,
                payload: newDiscussions,
            });
        });
    }, [assemblyId, nextDiscussionPage]);
    useEffect(() => {
        distribution.id &&
            getProducts(distribution.id, user).then(res => {
                const highlightedProductsByFarm = groupProductsByAlreadyOrderedFarms(
                    filterProducts(
                        HIGHLIGHTED_CATEGORY,
                        null,
                        null
                    )(res.products.map(product => ({ ...product, farm: { id: product.farmId } })))
                );
                setProducts(
                    R.flatten(R.pluck('products', highlightedProductsByFarm))
                        .sort((a, b) => a.sortingPriority - b.sortingPriority)
                        .slice(0, 4)
                );
            });
    }, [distribution, user]);
    if (!dataLoaded) {
        return (
            <div className="pi-hive-homepage-container-loading">
                <LoadingAnimation type="pi-spinner" />
            </div>
        );
    }

    const isInConstruction = assembly.status === 'construct';
    return (
        <>
            <div className="pi-hive-homepage-container">
                <Header assembly={assembly} />
                <div className="pi-assembly-content">
                    <div className="pi-assembly-main-col">
                        {distributions.length === 0 && !isInConstruction && (
                            <NoOpenSale assemblyId={assembly.id} />
                        )}
                        {isInConstruction && <HiveUnderConstruction assemblyId={assembly.id} />}
                        {!!distributions.length && (
                            <div className="pi-sales-block-distributions-container">
                                {distributions.map(distrib => (
                                    <Distribution
                                        key={distrib.id}
                                        distribution={distrib}
                                        isMember={user.isMember}
                                    />
                                ))}
                            </div>
                        )}
                        {!distributions.length && (
                            <HostSection
                                description={assembly.description}
                                leaderName={assembly.leader.firstName}
                            />
                        )}
                        {!!products.length && (
                            <div className="pi-hive-homepage-products">
                                <Heading size={4} productIdentity>
                                    {trans('products.weeklySelection')}
                                </Heading>
                                <div className="pi-hive-homepage-products-list">
                                    {products.map(product => (
                                        <a
                                            href={`/${i18n}/assemblies/${assembly.id}/collections/${distribution.id}/products/${product.id}`}
                                        >
                                            <ProductCard
                                                assemblyId={assembly.id}
                                                distributionId={distribution.id}
                                                product={product}
                                                isLabelDescriptionAvailable={false}
                                                mode={isSmallWidth ? null : MODE_LARGE}
                                                showProducer={false}
                                            />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}
                        {farmsGallery && (
                            <FarmsSection farmsGallery={farmsGallery} assemblyId={assemblyId} />
                        )}
                    </div>
                    <SideInfos
                        nearbyAssemblies={nearbyAssemblies}
                        assembly={assembly}
                        pickups={pickups}
                        onShowPlaceInMapBtnClick={handleShowPlaceInMapBtnClick}
                    />
                    {userIsMemberOfAssembly ? (
                        <NewsSection
                            discussions={discussions}
                            onNewCommentFormSubmit={handleNewCommentFormSubmit}
                            onNewDiscussionFormSubmit={handleNewDiscussionFormSubmit}
                            onDeleteComment={handleCommentDeletion}
                            onDeleteDiscussion={handleDiscussionDeletion}
                            onLoadMoreClick={handleLoadMoreMessageClick}
                            canLoadMoreDiscussions={canLoadMoreDiscussions}
                            userIsMember={userIsMemberOfAssembly}
                        />
                    ) : null}
                </div>
            </div>
            <TermsFooter />
            {placeModalEntity !== null && (
                <PlaceMapModal
                    modalTitle={trans('hiveHome.modal.map.title')}
                    placeType={placeModalEntityType}
                    isOpen={isPlaceModalOpen}
                    entity={placeModalEntity}
                    closeModal={handleCloseModal}
                    defaultZoom={13}
                />
            )}
        </>
    );
};

AssemblyHomeContainer.propTypes = {
    assemblyId: PropTypes.number.isRequired,
};

export default AssemblyHomeContainer;
