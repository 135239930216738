import { addTranslations } from 'modules/i18n';

const fakeTranslations = {
    'distributions.prepare.article':
        'https://support.laruchequiditoui.fr/hc/fr/articles/360021242734-Avoir-une-vente-toujours-ouverte',
    'distributions.prepare.explanation.title': 'Pourquoi préparer sa prochaine vente ?',
    'distributions.prepare.explanation':
        'Pour la lancer aussitôt que la précédente se termine. Avoir une vente toujours ouverte dans sa Ruche, c’est un meilleur accueil pour les Membres et plus de ventes pour les Producteurs.',
};

addTranslations('fr', fakeTranslations);
addTranslations('fr-FR', fakeTranslations);
addTranslations('fr-BE', fakeTranslations);
