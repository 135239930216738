import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n.js';
import { Text, Heading, Grid, Button, Box } from 'components/ui';
import { TextInput } from 'components/ui/form';
import { linkTo } from 'modules/utils';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { SAVED, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import checkIcon from 'icons/check.svg';
import * as R from 'ramda';

const EmptyDistributionProducts = ({
    isNotAMember,
    isAnonymous,
    assemblyId,
    joinAssembly,
    distributionStartDate,
    joinAssemblyStatus,
}) => {
    const { trans, i18n } = useI18n();
    const [email, setEmail] = useState('');

    const onChangeHandle = (_, value) => {
        setEmail(value);
    };

    return (
        <Box padding={false}>
            <div className="emptyDistributionProducts-container-image">
                <img
                    className="emptyDistributionProducts-image"
                    src="/assets/images/illustrations/Hive-empty-state.png"
                />
            </div>
            <div className="emptyDistributionProducts-content">
                <Heading rank={2} size={1} className="u-mb-4">
                    {trans('distribution.empty.title')}
                </Heading>
                <Grid sm="8|4" align="middle">
                    <div>
                        <Text className="u-mb-3">
                            {trans('distribution.empty.noProducts', {
                                '%distribution_date%': getLocalizedDate(
                                    i18n,
                                    'I',
                                    distributionStartDate
                                ),
                            })}
                        </Text>
                        {isNotAMember && !isAnonymous && (
                            <Text className="u-mb-3">{trans('distribution.empty.follow')}</Text>
                        )}
                        {!isNotAMember && !isAnonymous && (
                            <Text className="u-mb-3">{trans('distribution.empty.notify')}</Text>
                        )}
                        {isAnonymous && (
                            <Text className="u-mb-3">{trans('distribution.empty.email')}</Text>
                        )}
                    </div>
                    <div>
                        {isNotAMember && !isAnonymous && joinAssemblyStatus !== SAVED && (
                            <Button onClick={joinAssembly} block variant="success">
                                {trans('assembly.follow')}
                            </Button>
                        )}
                        {(isNotAMember && !isAnonymous) ||
                            (joinAssemblyStatus === SAVED && (
                                <Text color="success" icon={checkIcon} weight="bold">
                                    {trans('assembly.following')}
                                </Text>
                            ))}
                    </div>
                </Grid>
                {isAnonymous && (
                    <Grid sm="8|4">
                        <TextInput
                            id="count"
                            name="count"
                            type="text"
                            inputVariant="inverse"
                            onChange={onChangeHandle}
                            value={email}
                            block
                            className="emptyDistributionProducts-content-input"
                            placeholder={trans('global.label.email')}
                        />
                        <Button
                            block
                            disabled={R.isEmpty(email)}
                            variant="success"
                            href={linkTo(`join/assembly/${assemblyId}?email=${email}`)}
                        >
                            {trans('assembly.follow')}
                        </Button>
                    </Grid>
                )}
            </div>
        </Box>
    );
};

EmptyDistributionProducts.propTypes = {
    assemblyId: PropTypes.number.isRequired,
    isNotAMember: PropTypes.bool.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    joinAssembly: PropTypes.func.isRequired,
    distributionStartDate: PropTypes.string.isRequired,
    joinAssemblyStatus: statusPropType,
};

export default EmptyDistributionProducts;
