import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'ramda';

import useI18n from 'hooks/I18n/useI18n.js';

import { reactFormValidators } from 'modules/validation';

import AddressAutocomplete from 'components/ProductIdentity/AddressAutocomplete.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Input, { ERROR_MODE } from 'src/components/atoms/Input/Input.jsx';
import Textarea from 'src/components/atoms/Textarea/Textarea.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';

const DeliveryAddressForm = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { trans } = useI18n();
    const { assertNotBlank } = useMemo(() => reactFormValidators(trans), [trans]);

    return (
        <div className="pi-delivery-adress-form">
            <Text bold color="gray2" size="16px" lineHeight="24px">
                {trans('selectDelivery.yourDeliveryInformationTitle')}
            </Text>
            <div>
                <Label>{trans('user.firstname')}*</Label>
                <Input
                    {...register('firstName', {
                        validate: assertNotBlank('firstName'),
                    })}
                    mode={errors.firstName && ERROR_MODE}
                    error={errors.firstName?.message}
                />
            </div>
            <div>
                <Label>{trans('user.lastname')}*</Label>

                <Input
                    {...register('lastName', {
                        validate: assertNotBlank('lastName'),
                    })}
                    mode={errors.lastName && ERROR_MODE}
                    error={errors?.lastName?.message}
                />
            </div>
            <div>
                <AddressAutocomplete
                    {...register('street', {
                        validate: assertNotBlank('street'),
                    })}
                    label={trans('address.numberWay')}
                    clientErrors={errors?.street || []}
                    showErrors={!isEmpty(errors)}
                />
            </div>
            <div className="zipCode-container">
                <Label>{trans('address.zip')}*</Label>

                <Input
                    {...register('zipCode', {
                        validate: assertNotBlank('zipCode'),
                    })}
                    mode={errors.zipCode && ERROR_MODE}
                    error={errors?.zipCode?.message}
                />
            </div>
            <div className="city-container">
                <Label>{trans('global.city')}*</Label>

                <Input
                    {...register('city', {
                        validate: assertNotBlank('city'),
                    })}
                    mode={errors.city && ERROR_MODE}
                    error={errors?.city?.message}
                />
            </div>
            <div>
                <Label>{trans('address.country')}*</Label>

                <Input
                    {...register('country', {
                        validate: assertNotBlank('country'),
                    })}
                    emptyOption={trans('address.countryChoose')}
                    mode={errors.country && ERROR_MODE}
                    error={errors?.country?.message}
                />
            </div>
            <div>
                <Label>{trans('address.phoneNumber')}*</Label>
                <Input
                    {...register('phoneNumber', {
                        validate: assertNotBlank('phoneNumber'),
                    })}
                    type="tel"
                    mode={errors.phoneNumber && ERROR_MODE}
                    error={errors?.phoneNumber?.message}
                />
            </div>
            <div>
                <Label>{trans('address.additional')}</Label>

                <Textarea
                    {...register('additionalInformation', {
                        maxLength: 255,
                    })}
                />
            </div>
        </div>
    );
};

export default DeliveryAddressForm;
