import React from 'react';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import InvoicesPage from './InvoicesPage.jsx';
import { getMyInvoices } from 'models/invoices';

export class InvoicesContainer extends React.Component {
    state = {
        distributions: [],
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        getMyInvoices().then(({ distributions }) => {
            this.setState({
                distributions,
                status: READY,
            });
        });
    }

    render() {
        return <InvoicesPage distributions={this.state.distributions} status={this.state.status} />;
    }
}

export default InvoicesContainer;
