import moment from 'moment';
import { momentNow } from 'models/date';
import { get, post, patch } from 'modules/api';
import { toArray } from 'modules/utils/list';
import * as R from 'ramda';

export const workflow = [
    'sale.preparation',
    'sale.opened',
    'sale.finalization',
    'distribution.opened',
    'distribution.finalized',
];

const preparationStatuses = ['sale.init', 'sale.preparation', 'sale.canceled'];

function step(stepName) {
    return workflow.indexOf(stepName);
}

function getTimeObject(timeString) {
    const timeArray = timeString.split(':');
    return {
        hours: timeArray[0],
        minutes: timeArray[1],
        seconds: timeArray[2],
    };
}

export function getDistributionStart(distribution) {
    return distribution.distributionDate;
}

export function getDistributionStartsIn(distribution) {
    return moment(distribution.distributionDate).from(momentNow());
}

export function getDistributionEnd(distribution) {
    if (distribution.distributionEndDate) {
        return moment.utc(distribution.distributionEndDate).toISOString();
    }

    const timeEnd = getTimeObject(distribution.timeEnd);
    return moment(distribution.distributionDate)
        .set(timeEnd)
        .toISOString();
}

export function getDayBeforeSaleEndDate(distribution) {
    return moment(distribution.saleEndDate)
        .subtract(4, 'hours')
        .toISOString();
}

export function groupByDayAndTimeStarts(distributions) {
    const distributionsByDay = R.pipe(
        R.groupBy(distrib => moment(distrib.distributionDate).format('YYYY-MM-DD')),
        R.toPairs,
        R.map(([date, distributionsAtDay]) => ({ date, distributionsAtDay })),
        R.sortBy(R.prop('date'))
    )(distributions);

    return distributionsByDay.map(day => {
        return {
            date: day.date,
            timeStarts: groupByTimeStarts(day.distributionsAtDay),
        };
    });
}

export function groupByTimeStarts(distributionList) {
    return R.pipe(
        R.groupBy(R.prop('timeStart')),
        R.toPairs,
        R.map(([timeStart, distributions]) => ({ timeStart, distributions })),
        R.sortBy(R.prop('timeStart'))
    )(distributionList);
}

export function saleCanBePrepared(distribution) {
    return (
        preparationStatuses.indexOf(distribution.status) !== -1 &&
        (!distribution.saleEndDate || momentNow().isBefore(distribution.saleEndDate)) // Not sure why we need to check that
    );
}

export function saleCanStart(distribution) {
    return distribution.status === 'sale.preparation' && distribution.isOpenable === true;
}

export function saleHasStarted(distribution) {
    return step(distribution.status) >= step('sale.opened');
}

export function producerRequestedProductsRemovalFromSale(distribution) {
    return distribution.productsRemoval && distribution.productsRemoval.requestedAt;
}

export function producerRemovedProductsFromSale(distribution) {
    return distribution.productsRemoval && distribution.productsRemoval.doneAt;
}

export function saleHasEnded(distribution) {
    return step(distribution.status) > step('sale.opened');
}

export const saleIsOpen = distribution => !saleHasEnded(distribution);

export function distributionCanStart(distribution) {
    return (
        distribution.status === 'opened' && momentNow().isBefore(getDistributionStart(distribution))
    );
}

export function distributionHasStarted(distribution) {
    return (
        step(distribution.status) >= step('distribution.opened') &&
        momentNow().isAfter(getDistributionStart(distribution))
    );
}

export function distributionHasEnded(distribution) {
    return (
        step(distribution.status) >= step('distribution.opened') &&
        momentNow().isAfter(getDistributionEnd(distribution))
    );
}

export function transfersCanStart(distribution) {
    return distribution.status === 'sale.finalized';
}

export function isLastDay(distribution) {
    const now = momentNow();
    const saleEndDay = getDayBeforeSaleEndDate(distribution);
    const hoursUntilSaleEnd = moment(saleEndDay).diff(now, 'hours');

    return hoursUntilSaleEnd < 24;
}

export function getSaleEndDateInfo(distribution) {
    const now = momentNow();
    const realSaleEndDate = distribution.saleEndDate;
    const saleEndDay = getDayBeforeSaleEndDate(distribution);
    const hoursUntilSaleEnd = moment(saleEndDay).diff(now, 'hours');

    return {
        isLastDay: hoursUntilSaleEnd < 24,
        isLastTwoDays: hoursUntilSaleEnd < 48,
        closingInReal: moment(realSaleEndDate).from(now),
        closingInMember: moment(realSaleEndDate)
            .subtract(3, 'hours')
            .from(now), // strip the extra 3 hours for members
        saleEndDay,
    };
}

export function hasSomeNotEndedSales(distributions) {
    return !distributions.every(saleHasEnded);
}

export function productAlreadyRemoved(distribution) {
    return R.not(R.pathEq(['productsRemoval', 'doneAt'], null, distribution));
}

export function getOrganizationForFarmAndDistribution(farmId, distributionId) {
    return get(`organization/farm/${farmId}/distribution/${distributionId}`);
}

export function saveOrganizationForFarmAndDistribution(farmId, distributionId, organization) {
    const url = `organization/farm/${farmId}/distribution/${distributionId}/save`;
    const payload = { organization };
    return post(url, payload);
}

export function finalizeSale(distributionId) {
    const url = `distributions/${distributionId}`;
    const payload = { action: 'distribution_open_action' };
    return patch(url, payload);
}

export function getCurrentDistributions(assembly) {
    return R.pipe(
        R.path(['_links', 'currentSale']),
        // If there is only one distribution, currentSale is an object, otherwise it's an array… '-_-
        toArray,
        R.sortBy(R.prop('distributionDate'))
    )(assembly);
}

export const getNextSaleToPrepare = nextSalesToPrepare => {
    const nextSaleInPreparation = R.find(
        R.both(
            x => x.id !== null,
            y => y.status !== 'sale.canceled'
        )
    )(nextSalesToPrepare);
    const nextSaleToPrepare = R.find(R.propEq('status', 'sale.init'))(nextSalesToPrepare);

    return nextSaleInPreparation || nextSaleToPrepare;
};

export const getDistributionById = distributionId => get(`distributions/${distributionId}`);

export const getFarms = distributionId => get(`distributions/${distributionId}/farms`);

export const getSourceFarms = distributionId => get(`distributions/${distributionId}/source-farms`);

export const getProducts = (distributionId, user) => {
    let url = `distributions/${distributionId}/products?category=_all&page=1&perPage=10000`;
    if (!user.anonymous) {
        url += '&withAlreadyBoughtProductsInfo=true';
    }
    return get(url);
};

export const getDistributionDetails = (distributionId, view = 'flat') =>
    get(`distributions/${distributionId}?view=${view}`);

export const getOrdersNumbers = distributionId =>
    get(`distributions/${distributionId}/orders_numbers`);

export const getMaxPossibleRemovalDateFromDistributions = distributions => {
    if (R.isEmpty(distributions)) {
        return null;
    }
    const maxSaleEndDatesForSimpleDistributions = R.pipe(
        R.map(distribution => moment(distribution.saleEndDate)),
        moment.max
    )(distributions);
    return maxSaleEndDatesForSimpleDistributions;
};

export const getPossibleSendingMessageDays = distribution => {
    const limit = moment(distribution.lastDayToSendMail).startOf('day');
    const today = moment().startOf('day');
    let current = moment(distribution.lastDayToSendMail)
        .subtract(3, 'days')
        .startOf('day');
    let days = [];

    if (current < today) {
        current = today;
    }

    while (current <= limit) {
        days = R.append(current.format('YYYY-MM-DD'), days);
        current = current.add(1, 'days');
    }

    return days;
};
