import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Button, Heading, Icon, Text, Modal } from 'components/ui';
import Badge from 'components/Badge.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { linkTo, filerPhoto } = Utils;
import { createFarm, isEligibleForPro } from 'models/users';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, refreshCurrentUser } from 'modules/currentUser';
import AppChannel from 'modules/channels/App';

const OpenFarmButton = connectToI18n(({ trans }) => {
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const user = useSelector(userSelector);
    const dispatch = useDispatch();

    const handleOpenFarmSpace = () => {
        if (isEligibleForPro(user)) {
            createFarm().then(() => {
                dispatch(refreshCurrentUser()).then(() => {
                    AppChannel.commands.execute('navigate', 'producers/folder', true);
                });
            });
        } else {
            AppChannel.commands.execute('navigate', 'validate/scu/producer', true);
        }
    };

    return (
        <Fragment>
            <Button
                weight="link"
                onClick={() => setConfirmModalOpen(true)}
                className="button--noPadding test-open-farm-button"
                variant="primary"
            >
                {trans('menu.openProviderFolder')}
            </Button>
            {isConfirmModalOpen && (
                <Modal
                    isOpened
                    closeModal={() => setConfirmModalOpen(false)}
                    title={
                        <span
                            dangerouslySetInnerHTML={{
                                __html: trans('modals.openFarm.title'),
                            }}
                        />
                    }
                >
                    <div>
                        <Text
                            className="u-mb-8"
                            dangerouslySetInnerHTML={{
                                __html: trans('modals.openFarm.text'),
                            }}
                        />
                        <Button
                            variant="primary"
                            block
                            onClick={handleOpenFarmSpace}
                            className="confirmOpenFarm-button"
                        >
                            {trans('modals.openFarm.cta')}
                        </Button>
                        <Button block weight="link" onClick={() => setConfirmModalOpen(false)}>
                            {trans('global.cancel')}
                        </Button>
                    </div>
                </Modal>
            )}
        </Fragment>
    );
});

export class FarmSection extends React.Component {
    static propTypes = {
        farm: PropTypes.object,
        shouldDisplayProviderFolder: PropTypes.bool,
        shouldDisplayAccounting: PropTypes.bool,
        shouldDisplayAssemblies: PropTypes.bool,
        badgeValue: PropTypes.number,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const {
            farm,
            shouldDisplayProviderFolder,
            shouldDisplayAccounting,
            shouldDisplayAssemblies,
            badgeValue,
            trans,
        } = this.props;

        if (!farm) {
            return <OpenFarmButton />;
        }

        const farmName = farm.name ? (
            <span className="farmSection-name">{farm.name}</span>
        ) : (
            <span className="farmSection-nameToBeDefined">{trans('global.toBeDefined')}</span>
        );

        const badge =
            badgeValue && badgeValue > 0 ? (
                <Badge className="farmSection-badge">{badgeValue}</Badge>
            ) : null;

        const isFarmValidated = farm.status === 'validated farm';
        const mainLink = isFarmValidated ? 'producers/me/collections' : 'producers/folder';

        return (
            <div>
                <Heading size={2} className="u-mb-2">
                    {trans('navigation.context.farmTitle_new')}
                </Heading>
                <a className="farmSection" href={linkTo(mainLink)}>
                    <img
                        className="farmSection-picture"
                        src={filerPhoto(farm.photos && farm.photos[0], 'small', 'user')}
                    />
                    {farmName}
                    <Icon src="angle-right" color="maroon" size="xx-large" />
                </a>
                {isFarmValidated && shouldDisplayProviderFolder && (
                    <a className="farmSection" href={linkTo('producers/folder')}>
                        <img
                            className="farmSection-farmIcon"
                            src="/assets/images/navigation/mobile/icon-mobile-dossierprod-white.svg"
                        />
                        <span className="farmSectionFlex-folderLink">
                            {trans('global.providerFolder')}
                        </span>
                        <Icon src="angle-right" color="maroon" size="xx-large" />
                    </a>
                )}
                {isFarmValidated && shouldDisplayAccounting && (
                    <a className="farmSection" href={linkTo('producers/accounting')}>
                        <img
                            src="/assets/images/icons/icon-accounting.svg"
                            className="farmSection-farmIcon"
                        />
                        <span className="farmSectionFlex-folderLink">
                            {trans('navigation.left.accounting')}
                        </span>
                        <Icon src="angle-right" color="maroon" size="xx-large" />
                    </a>
                )}
                {shouldDisplayAssemblies && (
                    <a className="farmSection" href={linkTo('producers/me/assemblies')}>
                        <img
                            src="/assets/images/icons/icon-assembly.svg"
                            className="farmSection-farmIcon"
                        />
                        <span className="farmSectionFlex-folderLink">
                            {trans('navigation.bottom.assemblies')}
                        </span>
                        {badge}
                        <Icon src="angle-right" color="maroon" size="xx-large" />
                    </a>
                )}
            </div>
        );
    }
}

export default connectToI18n(FarmSection);
