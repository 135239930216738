import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import * as R from 'ramda';
import { debounce } from 'underscore';
import { fetchCountriesIfNeeded } from 'modules/countries';
import { fetchCities } from 'models/countries.js';

import { getCountriesForSelect } from 'modules/countries/selectors';
import useI18n from 'hooks/I18n/useI18n';

import { reactFormValidators } from 'modules/validation';

import Text from 'components/ProductIdentity/Text.jsx';
import Input, { ERROR_MODE } from 'src/components/atoms/Input/Input.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';
import PasswordValidation from 'src/components/organisms/PasswordValidation/PasswordValidation.jsx';
import Select from 'src/components/atoms/Select/Select.jsx';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from 'src/components/molecules/Checkbox/Checkbox.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';

const FormRegisterCheckout = () => {
    const { trans } = useI18n();
    const [cities, setCities] = useState([]);
    const dispatch = useDispatch();
    const countries = useSelector(getCountriesForSelect());
    const countriesForNationalitiesSelect = useSelector(getCountriesForSelect('code'));
    const methods = useFormContext();
    const {
        register,
        resetField,
        watch,
        setValue,
        formState: { errors },
    } = methods;
    const refreshCitySelect = debounce((country, zip) => {
        // don't search if one is "empty"
        if (!country || zip === '') {
            cities.length > 0 && setCities([]);
            return;
        }
        fetchCities({ zipCode: zip, country }).then(data => {
            setCities(
                data.cities.map(city => ({
                    label: city.name,
                    value: city.id.toString(),
                }))
            );

            if (data.cities.length > 0) {
                setValue('cityId', data.cities[0].id);
                setValue('city', data.cities[0].id);
            }
        });
    }, 300);

    const { assertNotBlank, assertValidEmail, assertValidPassword, assertChecked } = useMemo(
        () => reactFormValidators(trans),
        [trans]
    );
    const firstNameProps = {
        mode: errors.firstName && ERROR_MODE,
        error: R.path(['firstName', 'message'], errors),
        ...register('firstName', { validate: assertNotBlank('firstName') }),
    };
    const lastNameProps = {
        mode: errors.lastName && ERROR_MODE,
        error: R.path(['lastName', 'message'], errors),
        ...register('lastName', { validate: assertNotBlank('lastName') }),
    };
    const mailProps = {
        mode: errors.email && ERROR_MODE,
        error: R.path(['email', 'message'], errors),
        ...register('email', {
            validate: assertValidEmail('email'),
            setValueAs: v => v.trim(),
        }),
    };
    const passwordProps = {
        mode: errors.plainPassword && ERROR_MODE,
        error: R.path(['plainPassword', 'message'], errors),
        ...register('plainPassword', {
            validate: assertValidPassword('plainPassword'),
            setValueAs: v => v.trim(),
        }),
    };
    const phoneProps = {
        mode: errors.phone && ERROR_MODE,
        error: R.path(['phone', 'message'], errors),
        ...register('phone', { validate: assertNotBlank('phone') }),
    };

    const nationalityProps = {
        mode: errors.nationality && ERROR_MODE,
        error: R.path(['nationality', 'message'], errors),
        ...register('nationality', { validate: assertNotBlank('nationality') }),
    };
    const countriesProps = {
        mode: errors.countries && ERROR_MODE,
        error: R.path(['countries', 'message'], errors),
        ...register('countries', {
            validate: assertNotBlank('countries'),
            onChange: e => {
                resetField('zipCode');
                resetField('city');
                resetField('cityId');
                refreshCitySelect(e.target.value, '');
            },
        }),
    };
    const country = watch('countries');

    const zipCodeProps = register('zipCode', {
        onChange: e => {
            resetField('city');
            resetField('cityId');
            refreshCitySelect(country, e.target.value);
        },
        validate: assertNotBlank('zipCode'),
    });
    const cityProps = {
        mode: errors.city && ERROR_MODE,
        error: R.path(['city', 'message'], errors),
        ...register('city', {
            validate: assertNotBlank('city'),
            onChange: e => {
                setValue('city', e.target.value);
                setValue('cityId', e.target.value);
            },
        }),
    };
    const streetProps = {
        mode: errors.street && ERROR_MODE,
        error: R.path(['street', 'message'], errors),
        ...register('street', { validate: assertNotBlank('street') }),
    };
    const birthdayDateProps = {
        mode: errors.birthday && ERROR_MODE,
        error: R.path(['birthday', 'message'], errors),
        ...register('birthday', { validate: assertNotBlank('birthday') }),
    };
    const acceptedTOSProps = {
        error: R.path(['hasAcceptedTOS', 'message'], errors),
        ...register('hasAcceptedTOS', { validate: assertChecked('hasAcceptedTOS') }),
    };

    useEffect(() => {
        dispatch(fetchCountriesIfNeeded());
    }, [dispatch]);

    return (
        <form className="nd-checkout-create-account-form">
            <Text
                className="nd-checkout-create-account-form-heading"
                size="16px"
                lineHeight="24px"
                color="gray2"
                weight="600"
            >
                {trans('checkout.account.personal_information.title')}
            </Text>
            <Text
                className="nd-checkout-create-account-form-legend"
                size="16px"
                lineHeight="24px"
                weight="400"
                color="gray2"
            >
                {trans('checkout.account.personal_information.legend')}
            </Text>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="firstName">{trans('global.placeHolder.firstName')}*</Label>
                <Input {...firstNameProps} id="firstName" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="lastName">{trans('global.placeHolder.lastName')}*</Label>
                <Input {...lastNameProps} id="lastName" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="email">{trans('global.label.email')}*</Label>
                <Input {...mailProps} id="email" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="plainPassword">{trans('global.password')}*</Label>
                <PasswordValidation password={passwordProps} id="plainPassword" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="phone">{trans('address.phoneNumber')}*</Label>
                <Input {...phoneProps} id="phone" />
            </div>
            <Text
                className="nd-checkout-create-account-form-heading"
                size="16px"
                lineHeight="24px"
                color="gray2"
                weight="600"
            >
                {trans('checkout.account.payment_information.title')}
            </Text>
            <div className="nd-checkout-create-account-form-legend">
                <Text
                    size="16px"
                    lineHeight="24px"
                    color="gray2"
                    weight="400"
                    dangerouslySetInnerHTML={{
                        __html: trans('checkout.account.payment_information.legend'),
                    }}
                />
                <Link to="/support/kyc-information" target="_blank" data-bypass>
                    {trans('global.knowMore')}
                </Link>
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="countries">{trans('address.country')}*</Label>
                <Select
                    {...countriesProps}
                    placeholder={trans('address.countryChoose')}
                    id="countries"
                    options={countries}
                    defaultValue=""
                />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="street">{trans('address.numberWay')}*</Label>
                <Input {...streetProps} id="street" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="zipCode">{trans('address.zip')}*</Label>
                <Input {...zipCodeProps} id="zipCode" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="city">{trans('address.city')}*</Label>
                <Select {...cityProps} id="city" options={cities} readOnly={cities.length < 2} />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="birthday">{trans('global.dateOfBirth')}*</Label>
                <Input {...birthdayDateProps} id="birthday" type="date" />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Label htmlFor="countries">{trans('global.nationality')}*</Label>
                <Select
                    {...nationalityProps}
                    placeholder={trans('address.countryChoose')}
                    id="nationality"
                    options={countriesForNationalitiesSelect}
                    defaultValue=""
                />
            </div>
            <div className="nd-checkout-create-account-form-section">
                <Checkbox {...acceptedTOSProps} id="hasAcceptedTOS">
                    <div
                        className="nd-text nd-link"
                        dangerouslySetInnerHTML={{
                            __html: trans('user.tos', { '%url%': '/p/terms' }),
                        }}
                    ></div>
                </Checkbox>
            </div>
        </form>
    );
};

FormRegisterCheckout.propTypes = {
    basketUuid: PropTypes.string.isRequired,
};
export default FormRegisterCheckout;
