import PropTypes from 'prop-types';
import React from 'react';
import { debounce } from 'underscore';
import { LOADING, READY } from 'modules/utils/ajaxStatuses';
import { connect } from 'react-redux';
import { fetchCountriesIfNeeded, getCountriesState } from 'modules/countries';
import { fetchCities } from 'models/countries.js';

function getCities(country, zipCode) {
    return fetchCities({
        zipCode,
        country,
    }).then(data =>
        data.cities.map(city => ({
            label: city.name,
            value: city.id.toString(),
        }))
    );
}

export class AddressInputGroup extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired, // (key, val) => void 0
        cityName: PropTypes.string,
        initialCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        initialZip: PropTypes.string,
        fetchCountriesIfNeeded: PropTypes.func.isRequired,
        countries: PropTypes.array.isRequired,
    };

    static defaultProps = {
        cityName: 'city',
    };

    state = {
        country: this.props.initialCountry || '',
        cities: [],
        citiesStatus: LOADING,
        zip: this.props.initialZip || '',
    };

    componentDidMount() {
        this.last = 0;
        this.props.fetchCountriesIfNeeded();

        if (this.props.initialCountry && this.props.initialZip) {
            this.refreshCitySelect(this.props.initialCountry, this.props.initialZip);
        }

        if (!this.props.initialCountry && this.props.countries[0]) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ country: this.props.countries[0].value });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.country === '' && this.props.countries.length > 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ country: this.props.countries[0].value });
        }
    }

    onCountryChange = (__, value) => {
        this.setState({ country: value, zip: '' });
        this.refreshCitySelect(value, '');
        this.props.onChange(this.props.cityName, '');
    };

    onZipChange = (__, value) => {
        this.setState({ zip: value });
        this.refreshCitySelect(this.state.country, value);
        this.props.onChange(this.props.cityName, '');
    };

    refreshCitySelect = debounce((country, zip) => {
        this.setState({
            cities: [],
        });
        // don't search if one is "empty"
        if (!country || zip === '') {
            return;
        }
        const last = ++this.last;
        this.setState({
            citiesStatus: LOADING,
        });
        getCities(country, zip).then(
            cities => {
                if (last === this.last) {
                    this.setState({
                        cities,
                        citiesStatus: READY,
                    });
                    if (cities.length === 1) {
                        this.props.onChange(this.props.cityName, cities[0].value);
                    }
                }
            },
            () => {} // ignore errors
        );
    }, 300);

    render() {
        const { cities, country, zip } = this.state;

        const { countries } = this.props;

        return this.props.children({
            countries,
            cities,
            country,
            zip,
            onCountryChange: this.onCountryChange,
            onZipChange: this.onZipChange,
            areCitiesReady: this.state.citiesStatus === READY,
        });
    }
}

export default connect(
    state => ({
        countries: getCountriesState(state).data.map(country => ({
            ...country,
            label: country.name,
            value: country.id.toString(),
        })),
    }),
    {
        fetchCountriesIfNeeded,
    }
)(AddressInputGroup);
